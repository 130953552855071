<template>
  <div class="flex flex-col">
    <div
      class="focus:shadow-outline shadow flex relative duration-75 bg-offwhite mb-4 rounded-xl"
      v-on-clickaway:mousedown="closeDropdown"
    >
      <span
        class="flex-grow-0 px-1 flex justify-center items-center typcn typcn-zoom leading-none text-xl duration-100"
      ></span>
      <input
        type="text"
        class="shadow-none flex flex-grow focus:shadow-none add-project-input rounded-xl"
        :class="[client && client.name === searchBox ? 'font-semibold' : '']"
        v-model="searchQuery"
        @click="menuActive = true"
        v-if="!client"
        placeholder="Otsi"
        autocomplete="chrome-off"
      />
      <div
        class="w-11/12 flex px-3 py-1"
        v-if="client"
        @click="menuActive = true"
      >
        <span
          class="items-center flex leading-none flex-grow bg-offwhite rounded-xl"
        >
          <h4 class="flex-grow">{{ client.name }}</h4>
          <button class="new-button-danger" @click="clearSelection">
            <span class="icon"
              ><img src="/bc21/trash.svg" class="h-4 filter-to-white"
            /></span>
          </button>
        </span>
      </div>
    </div>
    <div class="client-selector-menu w-full">
      <ul v-if="clientsLoaded">
        <transition-group name="list">
          <li
            class="flex flex-col cursor-pointer"
            :class="
              client && client.id === item.id
                ? 'bg-offwhite bg-opacity-50'
                : 'hover:bg-offwhite hover:bg-opacity-50 cursor-pointer'
            "
            v-for="item in clients.results"
            :key="item.id"
            @click="selectClient(item)"
          >
            <div class="flex py-2 px-2 justify-between">
              <h4>{{ item.name }}</h4>
              <span
                v-if="client && client.id === item.id"
                class="text-sm text-green font-hairline"
                >Valitud</span
              >
            </div>
            <div class="flex p-2" v-if="client && item.id === client.id">
              <div class="flex flex-col">
                <h4 class="mr-2" v-if="item.email">Meiliaadress</h4>
                <h4 class="mr-2" v-if="item.telephone_number">
                  Telefoninumber
                </h4>
                <h4
                  class="mr-2"
                  v-if="item.address && item.address.long_address"
                >
                  Aadress
                </h4>
                <h4 class="mr-2" v-if="item.register_code">Registrikood</h4>
              </div>
              <div class="flex flex-col px-2">
                <span v-if="item.email">{{ item.email }}</span>
                <span v-if="item.telephone_number">{{
                  item.telephone_number
                }}</span>
                <span v-if="item.address && item.address.long_address">{{
                  item.address.long_address
                }}</span>
                <span v-if="item.register_code">{{ item.register_code }}</span>
              </div>
            </div>
            <div class="flex w-full bg-offwhite rounded-xl h-1"></div>
          </li>

          <li
            v-if="clients.results.length === 0"
            :key="'noresult'"
            class="font-medium flex items-center"
          >
            <span
              class="typcn typcn-times bg-attention rounded-full p-1 flex items-center justify-center leading-none mr-2"
            ></span>
            Vastet ei leitud
          </li>
        </transition-group>
      </ul>
      <div class="flex justify-center">
        <paginator
          :page="page"
          :count="clients.count"
          @pageChange="changePage"
        ></paginator>
      </div>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import Paginator from "@/components/reusable/Paginator";
import { directive as onClickaway } from "vue-clickaway2";
import debounce from "lodash/debounce";

export default {
  name: "ExistingClientSelector",
  components: { Paginator },
  mixins: [RequestHandler],
  props: {
    selectedClient: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      client: null,
      clients: [],
      clientsLoaded: false,
      menuActive: false,
      searchQuery: "",
      page: 1,
      pageCount: null
    };
  },
  directives: {
    onClickaway: onClickaway
  },
  methods: {
    loadCompanyClients() {
      let url =
        "company/" +
        this.$store.state.companyData.activeCompany.uuid +
        "/clients/?page=" +
        this.page;

      if (this.searchQuery.length > 0) {
        url += "&filter=" + this.searchQuery;
      }
      this.apiRequest(url, "get", true).then(res => {
        this.clients = res.data;
        this.clientsLoaded = true;
        if (this.$route.query.client) {
          this.selectClient(
            res.data.results.find(
              x => x.id === parseInt(this.$route.query.client)
            )
          );
          this.selectExistingClient = true;
        }
      });
    },
    toggleMenu() {
      this.menuActive = !this.menuActive;
    },
    findClientById(id) {
      this.client = this.clients.results.find(x => x.id === id);
      this.client.existing = true;
      this.$emit("clientUpdated", this.client);
    },
    selectClient(item) {
      this.client = item;
      this.client.existing = true;
      this.$emit("clientUpdated", this.client);
    },
    clearSelection() {
      this.client = null;
      this.searchBox = "";
      this.$emit("clientUnselected");
    },
    closeDropdown() {
      this.menuActive = false;
      this.searchBox = "";
    },
    changePage(e) {
      this.page = e;
      this.loadCompanyClients();
    },
    debounceSearchQuery: debounce(function() {
      this.loadCompanyClients();
    }, 500)
  },
  mounted() {
    this.loadCompanyClients();
    if (this.selectedClient) {
      this.client = this.selectedClient;
      this.client.existing = true;
    }
  },
  watch: {
    selectedClient(val) {
      if (val !== this.client) {
        this.client = this.selectedClient;
      }
    },
    searchQuery() {
      this.debounceSearchQuery();
    }
  }
};
</script>

<style scoped lang="scss">
.client-selector-menu {
  @apply flex flex-col;
}
</style>
