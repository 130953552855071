<template>
  <div class="flex flex-col bg-white items-center mx-auto">
    <div class="flex w-6/12 mobile:w-full mx-auto flex-col">
      <h2 class="self-start mb-4">Lisa projekt</h2>
      <div class="flex flex-col w-full self-center mb-8">
        <h3 class="mb-4">
          Projekti detailid
        </h3>
        <form-label
          title="Pealkiri"
          :required="true"
          :valid="!$v.project.title.$invalid"
          :error="$v.project.title.$error"
        ></form-label>
        <input
          class="mb-4 add-project-input"
          v-model="project.title"
          :class="
            $v.$error && $v.project.title.$invalid ? 'shadow-dangeroutline' : ''
          "
        />
        <form-label title="Kirjeldus" :required="false"></form-label>
        <textarea
          rows="5"
          class="add-project-input"
          v-model="project.details"
        ></textarea>
      </div>
      <div class="flex flex-col mb-8">
        <div class="flex flex-row items-center mb-2">
          <h3 class="mr-2">Projekti asukoht</h3>
        </div>
        <location-inaddress
          v-if="!project.location"
          @locationChanged="handleLocationChange"
        >
        </location-inaddress>
        <div
          v-if="project.location"
          class="bg-offwhite w-full rounded-xl py-1 px-1 flex items-center mb-4 shadow"
        >
          <span class="pl-2">{{ project.location.ipikkaadress }}</span>
          <button
            class="new-button-danger ml-auto"
            @click="project.location = null"
          >
            <span class="icon"
              ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
            /></span>
            <span class="label">Tühjenda</span>
          </button>
        </div>
        <div
          v-if="project.location && mapInited"
          class="flex h-full w-full shadow rounded-xl mobile:hidden"
          style="min-height: 300px;"
        >
          <l-map
            class="h-full w-full z-10 rounded-xl"
            :options="mapOptions"
            :center="mapOptions.center"
            :bounds="mapOptions.bounds"
            :interactive="false"
          >
            <l-tile-layer
              :url="tileUrl"
              :attribution="'© OpenStreetMap contributors'"
              :position="'bottomleft'"
              class="h-full w-full justify-start flex"
            ></l-tile-layer>
          </l-map>
        </div>
      </div>
      <div class="flex flex-col w-full mb-8 self-center">
        <form-label
          :heading-level="3"
          title="Klient"
          :required="true"
          :valid="$v.client.name.required"
          :error="$v.client.name.$error"
        ></form-label>
        <client-selector
          :project="project"
          @clientDataUpdated="handleClientDataUpdate"
          @clientContactsUpdated="handleClientContactUpdate"
          @blockSaving="handleBlockSaving"
        ></client-selector>
      </div>
      <div
        v-if="$v.$error && $v.$invalid"
        class="error-box w-full mb-6 items-center"
        id="error-box"
      >
        <div class="flex w-1/12 items-center">
          <span class="typcn typcn-times text-4xl leading-none"></span>
        </div>
        <div class="flex flex-col w-10/12 justify-center">
          <span class="flex" v-if="!$v.project.title.required"
            >Palun sisestage pealkiri</span
          >
          <span
            class="flex"
            v-if="existingClient === null && !$v.client.email.email"
            >Meiliaadress on ebakorrektne</span
          >
          <span class="flex" v-if="!$v.client.name.required"
            >Palun valige või lisage klient</span
          >
          <span
            class="flex"
            v-if="
              phoneNumberInput.length > 0 &&
                (!$v.phoneNumberInput.minLength ||
                  !$v.phoneNumberInput.maxLength)
            "
            >Telefoninumber on ebakorrektne (7-8 numbrit)</span
          >
        </div>
      </div>
      <div
        class="flex mobile:flex-col pb-3 justify-center items-center mobile:px-3"
        v-if="!savingBlocked"
      >
        <div
          class="flex mr-2 mobile:mr-0 mobile:mb-4 mobile:w-full mobile:justify-center w-1/2 justify-end"
        >
          <button
            class=" mobile:w-full"
            :class="$v.$invalid ? 'new-button-disabled' : 'new-button-green'"
            @click="$v.$invalid ? $v.$touch() : saveDraft(true)"
          >
            <span class="icon typcn typcn-tick"></span>
            <span class="label">Salvesta</span>
          </button>
        </div>
        <div
          class="flex ml-2 mobile:ml-0 mobile:justify-center w-1/2 mobile:w-full"
        >
          <button
            class="mobile:w-full"
            :class="$v.$invalid ? 'new-button-disabled' : 'new-button-pending'"
            @click="$v.$invalid ? $v.$touch() : saveDraft(false)"
          >
            <span class="icon">
              <img src="/icons/hidden.svg" class="filter-to-white" />
            </span>
            <span class="label">Salvesta ootele</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import {
  email,
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import ClientSelector from "@/components/company/clients/ClientSelector";
import LocationInaddress from "@/components/reusable/LocationInaddress";
import { latLng } from "leaflet";
import { LMap, LTileLayer } from "vue2-leaflet";
export default {
  components: {
    LocationInaddress,
    ClientSelector,
    LMap,
    LTileLayer
  },
  data() {
    return {
      imagePreview: [],
      imageData: [],
      error: false,
      countryCode: "+372",
      outsidePostUuid: null,
      phoneNumberInput: "",
      emailInput: "",
      existingClient: null,
      client: null,
      project: {
        title: "",
        details: "",
        location: null,
        date: null,
        client_is_company: false,
        publish: false
      },
      clientsLoaded: false,
      tileUrl: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      mapOptions: {
        zoom: 6,
        center: latLng(58.5953, 25.0136),
        interactive: false,
        bounds: null
      },
      mapInited: false,
      addingClient: false,
      clientContacts: [],
      savingBlocked: false
    };
  },
  mixins: [RequestHandler],
  watch: {
    phoneNumberInput() {
      const test = new RegExp(/[a-zA-Z\s]/);
      if (this.phoneNumberInput !== null) {
        this.phoneNumberInput = this.phoneNumberInput.replace(test, "");
      }
    },
    emailInput() {
      this.client.email = this.emailLowercased;
    },
    isCompany() {
      if (this.isCompany) {
        this.client.is_company = true;
        this.project.client_is_company = true;
      } else {
        this.client.is_company = false;
        this.project.client_is_company = false;
      }
    },
    handleLocationChange(e) {
      this.project.location = e;
      this.mapOptions.center = latLng(
        e.viitepunkt_b.substring(0, 2) + "." + e.viitepunkt_b.substring(3),
        e.viitepunkt_l.substring(0, 2) + "." + e.viitepunkt_l.substring(3)
      );
      let bounds = e.g_boundingbox.split(" ");
      let latLngBounds = [];
      for (let i = 0; i < bounds.length; i++) {
        latLngBounds.push(
          latLng(
            bounds[i].split(",")[0].substring(0, 2) +
              "." +
              bounds[i].split(",")[0].substring(3),
            bounds[i].split(",")[1].substring(0, 2) +
              "." +
              bounds[i].split(",")[1].substring(3)
          )
        );
      }
      this.mapOptions.bounds = latLngBounds;
      this.mapInited = true;
    }
  },
  computed: {
    emailLowercased() {
      return this.emailInput.toLowerCase();
    }
  },
  methods: {
    clearClient() {
      this.existingClient = null;
      this.isCompany = false;
      this.client.name = "";
      this.emailInput = "";
      this.phoneNumberInput = "";
      this.client.address = "";
      this.client.register_code = "";
      this.addingClient = false;
    },
    selectClient(client) {
      this.clearClient();
      this.existingClient = client;
      this.client.name = client.name;
      this.emailInput = client.email;
      if (client.telephone_number.length > 0) {
        if (client.telephone_number.substring(0, 4) !== "+372") {
          this.phoneNumberInput = client.telephone_number;
        } else {
          this.phoneNumberInput = client.telephone_number.substring(4);
        }
      }
      this.isCompany = client.is_company;
      this.client.register_code = client.register_code;
      this.client.address = client.address;
      this.addingClient = true;
    },

    saveDraft(publish) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.phoneNumberInput && this.phoneNumberInput.length > 0) {
          this.client.telephone_number =
            this.countryCode + this.phoneNumberInput;
        }
        if (this.client.existing) {
          this.project.client = this.client.id;
        } else {
          this.project.client = this.client;
        }
        if (this.project.location) {
          this.project.location = {
            ads_oid: this.project.location.ads_oid,
            latitude: this.project.location.viitepunkt_b,
            longitude: this.project.location.viitepunkt_l,
            long_address: this.project.location.ipikkaadress
          };
        }
        this.project.publish = publish;
        this.apiRequest(
          "/company/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/draft/add/",
          "post",
          true,
          this.project
        ).then(res => {
          if (res.status === 201) {
            this.outsidePostUuid = res.data.uuid;
            let clientId = res.data.client;
            if (this.clientContacts.length > 0) {
              this.saveClientContacts(clientId);
            }

            this.$router.push("/projects/" + res.data.uuid + "/");
          }
        });
      } else {
        let errorBox = document.getElementById("error-box");
        window.scroll(0, 0);
        setTimeout(function() {
          errorBox.classList.add("shake");
          errorBox.classList.add("shadow-dangeroutline");
          setTimeout(function() {
            errorBox.classList.remove("shake");
            errorBox.classList.remove("shadow-dangeroutline");
          }, 1000);
        }, 100);
      }
    },
    saveClientContacts(clientId) {
      this.apiRequest(
        "/company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/client/" +
          clientId +
          "/contacts/add/",
        "post",
        true,
        this.clientContacts
      ).then(res => {
        res;
      });
    },
    parseErrors() {
      return [];
    },
    handleLocationChange(e) {
      this.project.location = e;
      this.mapOptions.zoom = 18;
      this.mapOptions.center = latLng(
        e.viitepunkt_b.substring(0, 2) + "." + e.viitepunkt_b.substring(3),
        e.viitepunkt_l.substring(0, 2) + "." + e.viitepunkt_l.substring(3)
      );
      this.mapInited = true;
    },
    handleClientDataUpdate(e) {
      this.client = e;
    },
    handleClientContactUpdate(e) {
      this.clientContacts = e;
    },
    handleBlockSaving(e) {
      this.savingBlocked = e;
    },
    updatePreview() {
      for (let i = 0; i < this.$refs.postImages.files.length; i++) {
        this.imagePreview.push(
          URL.createObjectURL(this.$refs.postImages.files[i])
        );
      }
    }
  },
  validations() {
    let validationData = [];

    validationData.push({
      project: {
        title: {
          required
        }
      }
    });

    if (!this.existingClient) {
      validationData.push({
        client: {
          name: {
            required
          },
          email: {
            email
          }
        }
      });
    }
    if (this.phoneNumberInput !== null && this.phoneNumberInput.length > 0) {
      validationData.push({
        phoneNumberInput: {
          required,
          minLength: minLength(7),
          maxLength: maxLength(8),
          project: {
            details: {
              required
            },
            title: {
              required
            }
          }
        }
      });
    }
    let validationDict = {};
    for (let i = 0; i < validationData.length; i++) {
      for (let key in validationData[i]) {
        validationDict[key] = validationData[i][key];
      }
    }
    return validationDict;
  }
};
</script>

<style lang="scss">
.add-project-input {
  &::placeholder {
    color: #455a64;
    opacity: 0.8;
  }
}
#save-project {
  .icon {
    @apply text-3xl;
  }
}
</style>
