<template>
  <div class="flex flex-col">
    <div class="flex w-full rounded-xl shadow mb-4">
      <div
        class="client-selector-tab border-r rounded-l-xl w-1/2"
        @click="selectExistingClient = false"
        :class="!selectExistingClient ? 'client-selector-tab-active' : ''"
      >
        <span class="typcn typcn-plus mr-2"></span>
        <span>Lisa uus</span>
      </div>
      <div
        class="client-selector-tab border-l rounded-r-xl w-1/2"
        @click="selectExistingClient = true"
        :class="selectExistingClient ? 'client-selector-tab-active' : ''"
      >
        <span class="typcn typcn-th-list mr-2"></span>
        <span>Vali olemasolev</span>
      </div>
    </div>
    <transition name="fade">
      <div
        class="flex flex-col"
        v-if="Object.keys(clientData).length > 0 && selectExistingClient"
      >
        <h3 class="mb-2">Valitud klient</h3>
        <h4>{{ clientData.name }}</h4>
        <span class="mb-4">{{
          clientData.is_company ? "Äriklient" : "Eraklient"
        }}</span>
        <client-info-card class="flex flex-col mb-4" :client="clientData">
        </client-info-card>
        <div class="flex mb-4">
          <button class="new-button-danger" @click="clientData = {}">
            <span class="typcn typcn-times icon"></span>
            <span class="label">Tühista valik</span>
          </button>
        </div>
      </div>
    </transition>

    <add-client-form
      :project="project"
      v-if="!selectExistingClient"
      @clientUpdated="handleClientUpdate"
      @clientContactsUpdated="handleClientContactUpdate"
      @editingContact="blockSaving"
    ></add-client-form>
    <company-clients
      v-if="selectExistingClient"
      @clientUpdated="handleClientUpdate"
      ref="existingClients"
    ></company-clients>
  </div>
</template>

<script>
import AddClientForm from "@/components/company/clients/AddClientForm";
import CompanyClients from "@/components/project/ExistingClientSelector";
import RequestHandler from "@/assets/mixins/RequestHandler";
import ClientInfoCard from "@/components/reusable/ClientInfoCard";

export default {
  props: {
    project: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      selectExistingClient: true,
      clientData: {},
      isExisting: false
    };
  },
  mixins: [RequestHandler],
  components: {
    AddClientForm,
    CompanyClients,
    ClientInfoCard
  },
  watch: {
    clientData() {
      this.$emit("clientDataUpdated", this.clientData);
    }
  },
  methods: {
    handleClientUpdate(e) {
      this.clientData = e;
    },
    handleClientContactUpdate(e) {
      this.$emit("clientContactsUpdated", e);
    },
    blockSaving(e) {
      this.$emit("blockSaving", e);
    }
  }
};
</script>

<style lang="scss">
.client-selector-tab {
  @apply flex items-center bg-offwhite px-4 border-offerwhite py-1 cursor-pointer duration-100 font-medium;
  &:hover {
    @apply bg-offerwhite text-green;
  }
}
.client-selector-tab-active {
  @apply bg-offerwhite text-green;
  &:hover {
    @apply bg-offerwhite cursor-default;
  }
}
</style>
